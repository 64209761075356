import { DASHBOARD_ACHIEVEMENT, DASHBOARD_DOCUMENTSTATUS, DASHBOARD_FLEETORDER, DASHBOARD_FLEETPERFORMANCE, DASHBOARD_FLEETSTATUS, DASHBOARD_ORDERPERFORMANCE, DASHBOARD_REMINDER, DASHBOARD_SHIPMENTOUTSTANDING, DASHBOARD_SHIPMENTTODAY, DASHBOARD_SHIPMENTTOMORROW } from "../actions/types";

const initialState = {
  achievement: null,
  fleetOrder: null,
  shipmentToday: null,
  shipmentOutstanding: null,
  shipmentTomorrow: null,
  fleetPerformance: null,
  orderPerformance: null,
  documentStatus: null,
  fleetStatus: null,
  reminder: null,
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_ACHIEVEMENT:
      return { ...state, achievement: payload };
    case DASHBOARD_FLEETORDER:
      return { ...state, fleetOrder: payload };
    case DASHBOARD_SHIPMENTTODAY:
      return { ...state, shipmentToday: payload };
    case DASHBOARD_SHIPMENTOUTSTANDING:
      return { ...state, shipmentOutstanding: payload };
    case DASHBOARD_SHIPMENTTOMORROW:
      return { ...state, shipmentTomorrow: payload };
    case DASHBOARD_FLEETPERFORMANCE:
      return { ...state, fleetPerformance: payload };
    case DASHBOARD_ORDERPERFORMANCE:
      return { ...state, orderPerformance: payload };
    case DASHBOARD_DOCUMENTSTATUS:
      return { ...state, documentStatus: payload };
    case DASHBOARD_FLEETSTATUS:
      return { ...state, fleetStatus: payload };
    case DASHBOARD_REMINDER:
      return { ...state, reminder: payload };
    default:
      return state;
  }
}

