// alert.js
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// auth.js
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_NOORGANIZATION = "USER_NOORGANIZATION";
export const REGISTER = "REGISTER";
export const LOAD_REGISTER = "LOAD_REGISTER";

// data.js
export const REFRESH_DATA = "REFRESH_DATA";
export const LOAD_DATA = "LOAD_DATA";
export const ADD_DATA = "ADD_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const EXPORT_DATA = "EXPORT_DATA";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const CRUD_FAIL = "CRUD_FAIL";
export const DUPLICATE_NUMBER = "DUPLICATE_NUMBER";

// master.js
export const LOAD_USER = "LOAD_USER";
export const LOAD_ROLE = "LOAD_ROLE";
export const LOAD_MODULE = "LOAD_MODULE";

// ice.js
export const LOAD_FLEETCATEGORY = "LOAD_FLEETCATEGORY";
export const LOAD_FLEETTYPE = "LOAD_FLEETTYPE";
export const LOAD_DRIVER = "LOAD_DRIVER";
export const LOAD_FLEET = "LOAD_FLEET";
export const LOAD_CATEGORY = "LOAD_CATEGORY";
export const LOAD_INDUSTRY = "LOAD_INDUSTRY";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const LOAD_SECTOR = "LOAD_SECTOR";
export const LOAD_ADDRESS = "LOAD_ADDRESS";
export const LOAD_TERMOFPAYMENT = "LOAD_TERMOFPAYMENT";
export const LOAD_ACCOUNT = "LOAD_ACCOUNT";
export const LOAD_PRODUCT = "LOAD_PRODUCT";
export const LOAD_VENDOR = "LOAD_VENDOR";
export const LOAD_POOL = "LOAD_POOL";
export const LOAD_ITEMTYPE = "LOAD_ITEMTYPE";
export const LOAD_LOANTYPE = "LOAD_LOANTYPE";
export const LOAD_ROUTE = "LOAD_ROUTE";
export const LOAD_OUTSTANDING_SHIPMENT = "LOAD_OUTSTANDING_SHIPMENT";
export const LOAD_READY_ORDER = "LOAD_READY_ORDER";
export const LOAD_UNPAID_INVOICE = "LOAD_UNPAID_INVOICE";
export const LOAD_COST_CENTER = "LOAD_COST_CENTER";
export const SYNC_DATE = "SYNC_DATE";
export const ORDER_STATUS = "ORDER_STATUS";
export const SHIPMENT_STATUS = "SHIPMENT_STATUS";
export const LOAD_LAYOUT = "LOAD_LAYOUT";
export const LOAD_FLEET_ORDER_DEFAULT_COST = "LOAD_FLEET_ORDER_DEFAULT_COST";
export const LOAD_FLEETORDER = "LOAD_FLEETORDER";
export const LOAD_ORDER = "LOAD_ORDER";
export const LOAD_SHIPMENT = "LOAD_SHIPMENT";
export const CASHTRANSFER_STATUS = "CASHTRANSFER_STATUS";
export const LOADCASHTRANSFER_TRANSFERED = "LOADCASHTRANSFER_TRANSFERED";
export const LOAD_ORGANIZATION = "LOAD_ORGANIZATION";
export const LOAD_INVITATION = "LOAD_INVITATION";
export const LOAD_REQUEST = "LOAD_REQUEST";
export const ORGANIZATION_REQUEST = "ORGANIZATION_REQUEST";
export const ORGANIZATION_STATUS = "ORGANIZATION_STATUS";
export const LOAD_REQUESTNAME = "LOAD_REQUESTNAME";
export const LOAD_LISTORGANIZATION = "LOAD_LISTORGANIZATION";

// dashboard.js
export const DASHBOARD_ACHIEVEMENT = "DASHBOARD_ACHIEVEMENT";
export const DASHBOARD_FLEETORDER = "DASHBOARD_FLEETORDER";
export const DASHBOARD_SHIPMENTTODAY = "DASHBOARD_SHIPMENTTODAY";
export const DASHBOARD_SHIPMENTOUTSTANDING = "DASHBOARD_SHIPMENTOUTSTANDING";
export const DASHBOARD_SHIPMENTTOMORROW = "DASHBOARD_SHIPMENTTOMORROW";
export const DASHBOARD_FLEETPERFORMANCE = "DASHBOARD_FLEETPERFORMANCE";
export const DASHBOARD_ORDERPERFORMANCE = "DASHBOARD_ORDERPERFORMANCE";
export const DASHBOARD_DOCUMENTSTATUS = "DASHBOARD_DOCUMENTSTATUS";
export const DASHBOARD_FLEETSTATUS = "DASHBOARD_FLEETSTATUS";
export const DASHBOARD_REMINDER = "DASHBOARD_REMINDER";
//driverMobile
export const LOAD_DRIVERDELIVERED = "LOAD_DRIVERDELIVERED";
export const LOAD_DRIVERFLEET = "LOAD_DRIVERFLEET";
export const LOAD_DRIVERFLEETHISTORY = "LOAD_DRIVERFLEETHISTORY";
export const LOAD_DASHBBOARD = "LOAD_DASHBBOARD";
export const LOAD_DRIVERFLEETBYID = "LOAD_DRIVERFLEETBYID";
export const LOAD_ITEMBYFLEETORDERID = "LOAD_ITEMBYFLEETORDERID";
export const LOAD_ADDRESSBYFLEETORDERID = "LOAD_ADDRESSBYFLEETORDERID";
export const LOAD_GETFILESMOBILE = "LOAD_GETFILESMOBILE";
