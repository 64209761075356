import {
  LOAD_MODULE, LOAD_ROLE, LOAD_USER, LOAD_FLEETTYPE, LOAD_FLEETCATEGORY, LOAD_CATEGORY, LOAD_INDUSTRY,
  LOAD_CUSTOMER, LOAD_ADDRESS, ORDER_STATUS, LOAD_PRODUCT, LOAD_POOL, LOAD_ITEMTYPE, LOAD_ROUTE, SYNC_DATE,
  LOAD_DRIVER, LOAD_FLEET, LOAD_OUTSTANDING_SHIPMENT, LOAD_SECTOR, LOAD_LOANTYPE, LOAD_TERMOFPAYMENT,
  LOAD_READY_ORDER, LOAD_ACCOUNT, LOAD_UNPAID_INVOICE, LOAD_COST_CENTER, LOAD_LAYOUT, LOAD_VENDOR,
  LOAD_FLEET_ORDER_DEFAULT_COST, LOAD_ORDER, LOAD_FLEETORDER, LOADCASHTRANSFER_TRANSFERED, LOAD_ORGANIZATION,
  LOAD_INVITATION, LOAD_REQUEST, ORGANIZATION_REQUEST, LOAD_LISTORGANIZATION, LOAD_SHIPMENT,
  LOAD_REGISTER, LOAD_DRIVERFLEET, LOAD_DRIVERDELIVERED, LOAD_DRIVERFLEETBYID, LOAD_GETFILESMOBILE,
  LOAD_DRIVERFLEETHISTORY,
  LOAD_ITEMBYFLEETORDERID,
  LOAD_ADDRESSBYFLEETORDERID,
  LOAD_DASHBBOARD
} from "../actions/types";

const initialState = {
  user: null,
  role: null,
  module: null,

  fleetCategory: null,
  fleetType: null,
  fleet: null,
  driver: null,
  category: null,
  costCenter: null,
  industry: null,
  vendor: null,
  customer: null,
  layout: null,
  sector: null,
  address: null,
  product: null,
  termOfPayment: null,
  account: null,
  pool: null,
  itemType: null,
  loanType: null,
  route: null,
  shipment: null,
  unpaid: null,
  sync: null,
  defaultCost: null,
  fleetOrder: null,
  cashTransfer: null,

  order: null,
  organization: null,
  organizationNavbar: null,
  invitation: null,
  request: null,
  organizationRequest: null,
  registerinvitation: null,
  driverFleet: null,
  driverHistoryFleet: null,
  driverDelivered: null,
  addresses: null,
  item: null,
  dashboard: null,
};

export default function master(state = initialState, action) {
  const { type, payload } = action;

  // console.log(payload);
  switch (type) {
    case LOAD_USER:
      return { ...state, user: payload.data };
    case LOAD_ROLE:
      return { ...state, role: payload.data };
    case LOAD_MODULE:
      return { ...state, module: payload.data };

    // ICE
    case LOAD_FLEETCATEGORY:
      return { ...state, fleetCategory: payload.data };
    case LOAD_FLEETTYPE:
      return { ...state, fleetType: payload.data };
    case LOAD_FLEET:
      return { ...state, fleet: payload.data };
    case LOAD_DRIVER:
      return { ...state, driver: payload.data };
    case LOAD_CATEGORY:
      return { ...state, category: payload.data };
    case LOAD_VENDOR:
      return { ...state, vendor: payload.data };
    case LOAD_INDUSTRY:
      return { ...state, industry: payload.data };
    case LOAD_CUSTOMER:
      return { ...state, customer: payload.data };
    case LOAD_LAYOUT:
      return { ...state, layout: payload.data };
    case LOAD_SECTOR:
      return { ...state, sector: payload.data };
    case LOAD_ADDRESS:
      return { ...state, address: payload.data };
    case LOAD_PRODUCT:
      return { ...state, product: payload.data };
    case LOAD_TERMOFPAYMENT:
      return { ...state, termOfPayment: payload.data };
    case LOAD_ACCOUNT:
      return { ...state, account: payload.data };
    case LOAD_COST_CENTER:
      return { ...state, costCenter: payload.data };
    case LOAD_POOL:
      return { ...state, pool: payload.data };
    case LOAD_ITEMTYPE:
      return { ...state, itemType: payload.data };
    case LOAD_LOANTYPE:
      return { ...state, loanType: payload.data };
    case LOAD_ROUTE:
      return { ...state, route: payload.data };
    case LOAD_READY_ORDER:
      return { ...state, ready: payload };
    case LOAD_UNPAID_INVOICE:
      return { ...state, unpaid: payload };
    case LOAD_OUTSTANDING_SHIPMENT:
      return { ...state, shipment: payload };
    case LOAD_FLEET_ORDER_DEFAULT_COST:
      return { ...state, defaultCost: payload };
    case LOAD_FLEETORDER:
      return { ...state, fleetOrder: payload.data };
    case LOAD_ORDER:
      return { ...state, order: payload.data };
    case LOADCASHTRANSFER_TRANSFERED:
      return { ...state, cashTransfer: payload.data };
    case LOAD_ORGANIZATION:
      return { ...state, organization: payload.data };
    case LOAD_LISTORGANIZATION:
      return { ...state, organizationNavbar: payload.data };
    case LOAD_INVITATION:
      return { ...state, invitation: payload.data };
    case LOAD_REQUEST:
      return { ...state, request: payload.data };
    case LOAD_SHIPMENT:
      return { ...state, shipment: payload.data };
    case SYNC_DATE:
      return { ...state, sync: payload.data.length > 0 ? payload.data[0] : null };

    case ORDER_STATUS:
      return { ...state, order: payload.data };
    case ORGANIZATION_REQUEST:
      return { ...state, organizationRequest: payload.data };
    case LOAD_REGISTER:
      return { ...state, registerinvitation: payload.data };
    //driver mobile
    case LOAD_DRIVERFLEET:
      return { ...state, driverFleet: payload };
    case LOAD_DRIVERFLEETHISTORY:
      return { ...state, driverHistoryFleet: payload };
    case LOAD_DRIVERFLEETBYID:
      return { ...state, driverFleetById: payload };
    case LOAD_DRIVERDELIVERED:
      return { ...state, driverDelivered: payload };
    case LOAD_GETFILESMOBILE:
      return { ...state, mobileFiles: payload };
    case LOAD_ITEMBYFLEETORDERID:
      return { ...state, item: payload };
    case LOAD_ADDRESSBYFLEETORDERID:
      return { ...state, addresses: payload };
    case LOAD_DASHBBOARD:
      return { ...state, dashboard: payload };
    default:
      return state;
  }
}
