// const company = process.env.REACT_APP_COMPANY;
const baseURL = process.env.REACT_APP_BASE_URL;
// const baseURL = "https://myfleet-dev.mixtra.co.id";
// const baseURL = "https://myfleet-live.mixtra.co.id";
// const baseURL = "http://localhost:5000";
const googleAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

//const googleAPI = "AIzaSyDlObzleUXm0f2WU3Slq4FKzv54wO4WXO8";

const firebaseConfig = {
    apiKey: "AIzaSyC_1Fee7LpHzqEbG7qSqpIwbRSy33Gcthg",
    authDomain: "myfleet-notification.firebaseapp.com",
    projectId: "myfleet-notification",
    storageBucket: "myfleet-notification.appspot.com",
    messagingSenderId: "820564320649",
    appId: "1:820564320649:web:025943cb75ebb0d4028158",
    measurementId: "G-JBB4549M1N"
};

const firebasePublicKey = "BK9Kf-mQ2I_EFbbZSsMkt_Vq7anZq44ZRY5aoeFRNYWpJ7enzITqiN3axS3AksvoIefY4ajfexwvjPqUUU5OAXY";

const xenditPublicKey = "xnd_public_production_Ry4ws0ckWVfRF7AqYdw6pC4vesJiHuE2xZb78mlkqFT09VNtXDgG0UwNFYX7sqF";

export { baseURL, googleAPI, firebaseConfig, firebasePublicKey, xenditPublicKey };
