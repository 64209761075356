import { USER_LOADED, LOGIN_SUCCESS, LOGOUT, AUTH_ERROR, USER_NOORGANIZATION } from "../actions/types";
import setToken from '../utility/setToken';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true,
  user: null,
  roles: null,
  organizationID: localStorage.getItem('organizationID')
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      if (payload.data !== undefined && payload.data !== null)
        localStorage.setItem("driver", payload.data.email);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.data,
        roles: payload.roles,
        //organizationID: payload.organizationID
      };
    case USER_NOORGANIZATION:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.data,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      localStorage.setItem("organizationID", payload.data.organizationMembers[0].organizationID);
      localStorage.setItem("organizationText", payload.data.organizationMembers[0].name);
      localStorage.setItem("alias", payload?.alias);
      // console.log(localStorage)
      // setToken(payload.token);
      return {
        ...state,
        token: payload,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGOUT:
      const fcm = localStorage.getItem("fcm");
      window.localStorage.clear();
      localStorage.setItem("fcm", fcm);
      setToken(null, null);
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}